import { lighten } from 'polished';
import LoaderSpinner from 'react-loader-spinner';
import { motion, MotionProps } from 'framer-motion';
import styled, { css, DefaultTheme } from 'styled-components';

import { Modal } from '~/components';

const containerAttrs = (props: MotionProps) => ({
  ...props,
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  initial: { opacity: 0 }
});

export const Container = styled(motion.div).attrs(containerAttrs)`
  ${({ theme }) => css`
    height: 100%;
    background-color: ${theme.colors.gray200};

    display: flex;
    flex-direction: column;
  `}
`;

export const ActionButtons = styled.div<{ hasExtraAction?: boolean }>`
  ${({ theme, hasExtraAction }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    padding-top: ${hasExtraAction ? '2.5rem' : '4.75rem'};

    z-index: 20;
    background-color: ${theme.colors.backgroundColor};

    .action {
      position: relative;

      width: 100%;
      margin: 0 auto;
      max-width: 800px;
      padding: 0 1rem 1rem;

      > a {
        & + a {
          margin-top: 0.75rem;
        }
      }
    }
  `}
`;

const buttonsWrapModifiers = {
  isHide: () => css`
    margin-bottom: -10px;

    opacity: 0;
    visibility: hidden;
  `
};

export const ButtonsWrap = styled.div<{ isHide: boolean }>`
  ${({ theme, isHide }) => css`
    margin-bottom: 20px;

    opacity: 1;
    transition: 0.5s;
    visibility: visible;
    text-align: center;

    h2 {
      font-size: 1rem;
      font-weight: bold;
      line-height: 150%;

      margin-bottom: 12px;

      color: ${theme.colors.textColor};
    }

    ${isHide && buttonsWrapModifiers.isHide()}

    .google {
      border-color: ${theme.isDarkMode ? theme.colors.backgroundColor : theme.colors.gray300} !important;

      background-color: #fff;

      span {
        color: ${theme.isDarkMode ? theme.colors.backgroundColor : theme.colors.textColor};
      }
    }
  `}
`;

const loaderAttrs = ({ theme }: { theme: DefaultTheme }) => ({
  height: 30,
  type: 'Oval',
  color: lighten(0.07, theme.brandColors.primary || theme.colors.backgroundColor)
});

export const Loader = styled(LoaderSpinner).attrs(loaderAttrs)`
  margin: 6px auto 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BigLoader = styled(Loader).attrs({
  height: 50
})`
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
`;

export const ModalStyled = styled(Modal)`
  .content {
    padding: 0 1rem;

    h2 {
      margin-bottom: 12px;

      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
    }

    button:first-of-type {
      margin-bottom: 12px;
    }
  }
`;

export const GoomerExperienceWrap = styled.div`
  ${({ theme }) => css`
    min-height: 6rem;
    padding-top: 2rem;

    margin-top: auto;
    background-color: ${theme.colors.gray200};

    display: flex;
    justify-content: center;
  `}
`;

export const OrWrap = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 1rem;
    margin: 2rem -1rem 1rem -1rem;

    display: flex;

    .right-line {
      margin-left: 2rem;
    }

    .line {
      width: calc(50% - 1rem);
      border-top: 1px solid ${theme.colors.gray400};
    }

    .text {
      position: relative;
      left: 1rem;
      top: -0.5rem;
      height: 1rem;

      display: flex;
      align-items: center;

      font-size: 0.75rem;
      color: ${theme.colors.gray500};
    }
  `}
`;
