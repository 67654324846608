import React, { useState, useEffect, useMemo } from 'react';

import Link from 'next/link';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { GetServerSideProps, NextPage } from 'next';
import { showTranslations } from 'translation-check';
import { RiMailLine, RiRestaurantLine, RiTranslate } from 'react-icons/ri';

import { GoogleButtonIcon } from '~/assets';
import { IApplicationState } from '~/redux-tools/store';
import { SplashHeader } from '~/components/pages/Splash';
import { IEstablishmentSettings } from '~/interfaces/general';
import { fetchEstablishmentSettingsServerSide } from '~/services';
import { Button, GoomerExperience, NewWaves } from '~/components';
import { FeaturesEnum, LocalStorageThirkyEnum } from '~/interfaces/enums';
import {
  useSplash,
  useTranslator,
  useHasGoomerDomain,
  useHasFeatureAccess,
  useEstablishmentStatus,
  useUserAuthenticationStatus
} from '~/hooks';

import * as S from './styles';

interface PagesProps {
  slug: string;
  isQrCode?: boolean;
  action?: () => void;
  settings: IEstablishmentSettings;
}

const Splash: NextPage<PagesProps> = ({ slug, action, settings, isQrCode = false }) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);
  const userName = useSelector((state: IApplicationState) => state.user.data.name);
  const { isViewMode } = useSelector((state: IApplicationState) => state.establishment);

  const { getTranslation } = useTranslator();
  const hasGoomerDomain: boolean = useHasGoomerDomain();
  const { isUserAuthenticated } = useUserAuthenticationStatus();
  const [hasUserSignUpAccess] = useHasFeatureAccess({ featureId: FeaturesEnum.UserSignUp });
  const { isAbleToOrder, status: establishmentStatus } = useEstablishmentStatus({ isQrCode });

  const [isInstagramUserAgent, setIsInstagramUserAgent] = useState<boolean>(false);

  const {
    storeOpen,
    isLoading,
    gaTrackSignInOut,
    gaTrackSignInEnter,
    gaTrackInStoreMenu,
    gaTrackGoomerGoMenu,
    isLocalOrdersEnabled
  } = useSplash({
    slug,
    settings,
    isQrCode
  });

  const firstName = useMemo(() => userName?.split(' ')[0], [userName]);

  const linkText = useMemo(() => {
    if (settings?.specialty_name === 'Outros') return getTranslation('splash.seeProducts');

    return getTranslation('splash.seeMenu');
  }, [getTranslation, settings?.specialty_name]);

  const hasExtraAction = useMemo(() => {
    if (isUserAuthenticated) {
      return false;
    }

    return process.env.NEXT_PUBLIC_ENV !== 'production' || (!isQrCode && isLocalOrdersEnabled && storeOpen.isOpen);
  }, [isLocalOrdersEnabled, isQrCode, isUserAuthenticated, storeOpen.isOpen]);

  const actionToHomeText = useMemo(() => {
    const isDeliveryEnabled = settings?.mm_delivery_enabled;
    const isTakeawayEnabled = settings?.mm_takeaway_enabled;

    if (isDeliveryEnabled && isTakeawayEnabled) {
      return getTranslation('splash.accessMenu');
    }

    if (isDeliveryEnabled) {
      return getTranslation('splash.orderByDelivery');
    }

    if (isTakeawayEnabled) {
      return getTranslation('splash.orderByTakeaway');
    }

    return getTranslation('splash.seeMenu');
  }, [getTranslation, settings?.mm_delivery_enabled, settings?.mm_takeaway_enabled]);

  useEffect(() => {
    const raw = !!navigator && navigator.userAgent;
    const index = raw.indexOf('Instagram');

    setIsInstagramUserAgent(index !== -1);
  }, []);

  const renderAction: JSX.Element = useMemo(() => {
    return (
      <div className="action">
        {isLoading && <S.BigLoader />}

        <S.ButtonsWrap isHide={isLoading}>
          {isUserAuthenticated && firstName && (
            <h2>
              {getTranslation('general.greeting')}, {firstName}!
            </h2>
          )}

          {!isUserAuthenticated && hasUserSignUpAccess && (
            <>
              <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
                <p>{getTranslation('splash.loginTitle')}</p>

                {hasGoomerDomain && !isInstagramUserAgent && (
                  <Button
                    className="google"
                    style={{ position: 'relative' }}
                    onClick={() => gaTrackSignInEnter('google')}
                  >
                    <div style={{ left: '0.25rem', display: 'flex', position: 'absolute' }}>
                      <GoogleButtonIcon />
                    </div>
                    {getTranslation('login.continueWithGoogle')}
                  </Button>
                )}

                <Button style={{ position: 'relative' }} onClick={() => gaTrackSignInEnter('email')}>
                  <RiMailLine size="20px" style={{ left: '1rem', position: 'absolute' }} />
                  {getTranslation('login.continueWithEmail')}
                </Button>
              </div>

              <S.OrWrap>
                <div className="left-line line" />
                <div className="text">{getTranslation('general.or')}</div>
                <div className="right-line line" />
              </S.OrWrap>
            </>
          )}

          {!!isQrCode && !!action && (
            <Button onClick={action} data-test="btn-delivery-takeaway" style={{ position: 'relative' }}>
              <RiRestaurantLine size="20px" style={{ left: '1rem', position: 'absolute' }} />
              {getTranslation('splash.placeAnOrder')}
            </Button>
          )}

          {!isQrCode && (
            <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>
              <Button
                isGhost={false}
                data-test="btn-make-order"
                onClick={isAbleToOrder && !isViewMode ? gaTrackSignInOut : gaTrackGoomerGoMenu}
              >
                {isAbleToOrder && !isViewMode ? actionToHomeText : linkText}
              </Button>

              {isLocalOrdersEnabled && storeOpen.isOpen && (
                <Link href={'/information?type=splash-local-orders'}>
                  <Button onClick={gaTrackInStoreMenu}>{getTranslation('splash.orderInRestaurant')}</Button>
                </Link>
              )}
            </div>
          )}

          {process.env.NEXT_PUBLIC_ENV !== 'production' && (
            <Button style={{ marginTop: '1rem', position: 'relative' }} onClick={(): void => showTranslations(i18next)}>
              {/* eslint-disable-next-line i18next/no-literal-string */}
              <RiTranslate size="20px" style={{ left: '1rem', position: 'absolute' }} />
              Gerenciar traduções (ninja)
            </Button>
          )}
        </S.ButtonsWrap>
      </div>
    );
  }, [
    action,
    isQrCode,
    linkText,
    isLoading,
    firstName,
    isViewMode,
    isAbleToOrder,
    getTranslation,
    hasGoomerDomain,
    gaTrackSignInOut,
    actionToHomeText,
    storeOpen.isOpen,
    gaTrackInStoreMenu,
    gaTrackSignInEnter,
    isUserAuthenticated,
    hasUserSignUpAccess,
    gaTrackGoomerGoMenu,
    isInstagramUserAgent,
    isLocalOrdersEnabled
  ]);

  useEffect(() => localStorage.setItem(LocalStorageThirkyEnum.preRollVisibility, 'true'), []);

  return (
    <>
      <S.Container>
        <SplashHeader
          isQrCode={isQrCode}
          establishment={{
            name: settings.name,
            logo: settings.mm_logo_url,
            status: establishmentStatus,
            banner: settings.mm_banner_url,
            category: settings.specialty_name,
            phrase: settings.mm_splash_screen_message
          }}
        />

        <S.ActionButtons hasExtraAction={hasExtraAction}>{renderAction}</S.ActionButtons>

        <NewWaves backgroundColor={theme.colors.gray200} />

        <S.GoomerExperienceWrap>
          <GoomerExperience />
        </S.GoomerExperienceWrap>
      </S.Container>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<PagesProps> = async ({ req }) => {
  const emptySettings = {} as IEstablishmentSettings;

  try {
    const { slug, settings } = await fetchEstablishmentSettingsServerSide(req?.headers);

    return { props: { slug, settings: settings || emptySettings } };
  } catch (error) {
    return { notFound: (error as Error)?.message, props: { slug: '', settings: emptySettings } };
  }
};

export default Splash;
